#nav-icon4 {
  width: 18px;
  height: 14px;
  /* background-color: antiquewhite; */
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.1s ease-in-out;
  -moz-transition: 0.1s ease-in-out;
  -o-transition: 0.1s ease-in-out;
  transition: 0.1s ease-in-out;
  cursor: pointer;
  /* overflow: hidden; */
}

#nav-icon4 span {
  display: block;
  /* position: absolute; */
  height: 2px;
  width: 100%;
  position: relative;
  background: #151515;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.2s ease-in-out;
  -moz-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}

/* Icon 4 */

#nav-icon4 span:nth-child(1) {
  /* top: 12px; */
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#nav-icon4 span:nth-child(2) {
  width: 100%;
  -webkit-transition:
    width 0.1s ease-in-out,
    left 0.1s ease-in-out,
    opacity 0.1s ease-in-out;
  -moz-transition:
    width 0.1s ease-in-out,
    left 0.1s ease-in-out,
    opacity 0.1s ease-in-out;
  -o-transition:
    width 0.1s ease-in-out,
    left 0.1s ease-in-out,
    opacity 0.1s ease-in-out;
  transition:
    width 0.1s ease-in-out,
    left 0.1s ease-in-out,
    opacity 0.1s ease-in-out;
}

#nav-icon4 span:nth-child(3) {
  /* top: 36px; */
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#nav-icon4.open span:nth-child(1) {
  -webkit-transform: rotate(44deg);
  -moz-transform: rotate(44deg);
  -o-transform: rotate(44deg);
  transform: rotate(44deg);
  top: -0.3px;
  left: -0.1px;
}

#nav-icon4.open span:nth-child(2) {
  width: 0%;
  opacity: 0;
  left: -100%;
}

#nav-icon4.open span:nth-child(3) {
  -webkit-transform: rotate(-44deg);
  -moz-transform: rotate(-44deg);
  -o-transform: rotate(-44deg);
  transform: rotate(-44deg);
  /* top: -1px; */
  /* left: 0.5px; */
}
