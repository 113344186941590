@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'password';
  font-style: normal;
  font-weight: 400;
  src: url(https://jsbin-user-assets.s3.amazonaws.com/rafaelcastrocouto/password.ttf);
}

.loginpass {
  font-family: 'password';
}

:root,
html,
body {
  /* height: 100%; */
  position: relative;
  z-index: 0;
  background-color: white;
}

html {
  /* height: 100%; */
  position: fixed;
}

body {
  height: 100%;
  overscroll-behavior: none;
  -webkit-overflow-scrolling: touch;
  overflow: auto;
}

.blog-content-heading {
  scroll-margin-top: 100px;
}

.font-proxima_font {
  letter-spacing: -0.02em;
}

.custom-select {
  appearance: none;
  background: transparent;
  background-image: url('/icons/list_down.svg');
  background-size: 14px;
  background-repeat: no-repeat;
  background-position: calc(100% - 16px) center;
}

@media (max-width: 768px) {
  body {
    height: 100vh;
    width: 100vw;
    overflow-x: hidden;
  }
}

.scroll-touch {
  -webkit-overflow-scrolling: touch;
}

.banner-text {
  text-shadow: 0 0 4px #000;
}

.overlay {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: #0000004d; /* Black overlay with opacity 0.3 */
  pointer-events: auto; /* Disable pointer events on main and footer */
}

.link {
  position: relative;
  opacity: 1;
}

.progress-bar {
  clip-path: polygon(100% 1%, 0% 100%, 100% 100%);
}

.link::after {
  content: '';
  position: absolute;
  opacity: 1;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: currentColor;
  animation-name: underline;
  animation-duration: 0.3s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}

@keyframes underline {
  from {
    transform: scaleX(0);
  }
  to {
    transform: scaleX(1);
  }
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.no-scroll {
  overflow: hidden;
}

.body-scroll-lock {
  /* position: fixed; */
  width: 100%;
  overflow: hidden !important;
}

@media (min-width: 1024px) {
  .body-scroll-lock {
    overflow: auto;
  }
}

.thick-scrollbar::-webkit-scrollbar {
  display: none;
}

.client-slider .swiper-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

@media (min-width: 768px) {
  .thick-scrollbar {
    scrollbar-width: auto;
  }

  .thick-scrollbar::-webkit-scrollbar {
    display: block;
    width: 1vw;
    height: 40px;
  }

  .thick-scrollbar::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 50px;
    max-height: 50px;
  }

  .thick-scrollbar::-webkit-scrollbar-track {
    background-color: white;
    padding: 5px !important;
    position: relative;
  }

  .thick-scrollbar::-webkit-scrollbar {
    width: 13px;
    height: 40px;
  }
  .thick-scrollbar::-webkit-scrollbar-thumb {
    background-color: rgb(0, 0, 0, 0.4);
    border: 1px solid white;
    border-radius: 50px;
  }
  .thick-scrollbar::-webkit-scrollbar-button:single-button:vertical:decrement,
  .thick-scrollbar::-webkit-scrollbar-button:single-button:vertical:increment {
    height: 20px;
    width: 1vw;
    background-color: white;
    background-position: center;
    background-repeat: no-repeat;
  }
  .thick-scrollbar::-webkit-scrollbar-button:single-button:vertical:decrement {
    border-top-right-radius: 50%;
    border-top-left-radius: 50%;
    background-image: url('data:image/svg+xml, <svg width="13" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.4291 9.37893L7.25998 0.13986C7.22792 0.0960145 7.18807 0.0608014 7.14319 0.0366538C7.0983 0.0125062 7.04944 0 7 0C6.95056 0 6.9017 0.0125062 6.85681 0.0366538C6.81193 0.0608014 6.77208 0.0960145 6.74002 0.13986L0.570894 9.37893C0.532437 9.43551 0.508512 9.50362 0.501884 9.57537C0.495256 9.64713 0.506196 9.7196 0.533438 9.78442C0.560897 9.84902 0.603281 9.90342 0.655826 9.94151C0.70837 9.97959 0.768993 9.99985 0.830878 10H13.1691C13.231 9.99985 13.2916 9.97959 13.3442 9.94151C13.3967 9.90342 13.4391 9.84902 13.4666 9.78442C13.4938 9.7196 13.5047 9.64713 13.4981 9.57537C13.4915 9.50362 13.4676 9.43551 13.4291 9.37893Z" fill="black" fill-opacity="0.4"/></svg>');
  }
  .thick-scrollbar::-webkit-scrollbar-button:single-button:vertical:increment {
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 50%;
    background-image: url('data:image/svg+xml, <svg width="13" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.570894 0.621071L6.74002 9.86014C6.77208 9.90399 6.81193 9.9392 6.85681 9.96335C6.9017 9.98749 6.95056 10 7 10C7.04944 10 7.0983 9.98749 7.14319 9.96335C7.18807 9.9392 7.22792 9.90399 7.25998 9.86014L13.4291 0.621071C13.4676 0.564487 13.4915 0.496382 13.4981 0.424626C13.5047 0.352872 13.4938 0.280396 13.4666 0.215578C13.4391 0.150977 13.3967 0.0965767 13.3442 0.0584936C13.2916 0.0204105 13.231 0.000153542 13.1691 0L0.830878 0C0.768992 0.000153542 0.70837 0.0204105 0.655826 0.0584936C0.603281 0.0965767 0.560898 0.150977 0.533439 0.215578C0.506196 0.280396 0.495256 0.352872 0.501884 0.424626C0.508512 0.496382 0.532437 0.564487 0.570894 0.621071Z" fill="black" fill-opacity="0.4"/></svg> ');
  }
  .thick-scrollbar::-webkit-scrollbar-track {
    background-color: white;
  }
}

@media (max-width: 768px) {
  /* Remove blue highlight on click from small screens */
  * {
    -webkit-tap-highlight-color: transparent;
  }
}

.chevron {
  position: relative;
  width: 14px;
  height: 14px;
  cursor: pointer;
  flex-shrink: 0;
}

.chevron::before,
.chevron::after {
  content: '';
  position: absolute;
  width: 9px;
  height: 1.5px;
  /* border-radius: 1px; */
  background: black;
  transition: transform 0.3s ease;
}

.chevron::before {
  top: 50%;
  left: 0;
  transform: rotate(46deg);
}

.chevron::after {
  top: 50%;
  right: -0.5px;
  transform: rotate(-46deg);
}

.chevron.up::before {
  transform: rotate(-46deg);
}

.chevron.up::after {
  transform: rotate(46deg);
}

.chevron.down::before {
  transform: rotate(46deg);
}

.chevron.down::after {
  transform: rotate(-46deg);
}

.popup {
  z-index: 99999 !important;
}

.warning-input::after {
  content: '';
  position: absolute;
  top: 0;
  right: 12px; /* Adjust as needed */
  transform: translateY(14px);
  width: 24px; /* Adjust the size of the image */
  height: 24px; /* Adjust the size of the image */
  background-image: url('/icons/warning.svg');
  background-size: contain; /* Adjust as needed */
  background-repeat: no-repeat;
}

.product_card_list {
  list-style-image: url('/icons/tick-fill.svg');
}

.scroll-transition {
  transition: scroll-behavior 0.5s ease-in-out;
}

#map {
  appearance: none;
  background-image: url('data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2220%22%20height%3D%2220%22%20fill%3D%22%23000000%22%20viewBox%3D%220%200%20256%20256%22%3E%3Cpath%20d%3D%22M213.66%2C101.66l-80%2C80a8%2C8%2C0%2C0%2C1-11.32%2C0l-80-80A8%2C8%2C0%2C0%2C1%2C53.66%2C90.34L128%2C164.69l74.34-74.35a8%2C8%2C0%2C0%2C1%2C11.32%2C11.32Z%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat;
  background-position: right 0 top 50%;
  background-size: auto;
  padding-right: 20px;
  margin-right: 12px;
}

/* Hide close icon from map infobox */
.gm-style-iw-chr {
  display: none;
}

.in-the-box li {
  list-style: disc;
  list-style-position: inside !important;
}

.disc-points li {
  list-style: disc;
  list-style-position: inside !important;
}

.disc-points ul,
ol {
  margin-top: 8px !important;
}

.fslightbox-open {
  overflow: visible !important;
}

.vjs-control-bar {
  border-radius: 8px;
}

.information {
  background: radial-gradient(
    50% 47.5% at 50% 50%,
    rgba(10, 10, 10, 0.6) 0%,
    #0a0a0a 100%
  );
}
.information2 {
  background: radial-gradient(
    50% 47.5% at 50% 50%,
    rgba(10, 10, 10, 0) 0%,
    #0a0a0a 100%
  );
}

.article-btn {
  min-width: 200px;
  padding: 15px 0px;
  display: inline-block;
  text-align: center;
  border-width: 2px; /* border-2 */
  border-color: black; /* border-black */
  transition: all 0.2s; /* transition-all duration-200 */
  cursor: pointer; /* cursor-pointer */
  font-family: var(--proxima-font);
  border-radius: 9999px; /* rounded-full */
  font-size: 16px; /* text-[16px] */
  font-weight: 500; /* font-medium */
  background-color: black; /* bg-black */
  color: white !important; /* text-white */
  margin-left: auto !important;
  margin-right: auto !important;
  text-wrap: nowrap;
}

.article-btn:hover {
  background-color: transparent; /* hover:bg-transparent */
  color: black !important; /* hover:!text-black */
  text-decoration: none !important;
}

@media (max-width: 768px) {
  .article-btn {
    min-width: 100%;
  }

  .article-btn-wrapper {
    flex-direction: column;
    gap: 10px;
  }
}

.noLinkStyle {
  color: inherit !important;
  font-weight: normal !important;
  text-decoration: none !important;
  transition: none !important;
}

@media screen and (max-width: 768px) {
  .product-page .woot-widget-bubble {
    bottom: 70px !important;
  }
}

/* clears the ‘X’ from Internet Explorer */
input[type='search']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type='search']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
/* clears the ‘X’ from Chrome */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

.instagram-media {
  height: revert-layer !important;
}

@layer base {
  img {
    @apply inline-block;
  }
}

@media print {
  body {
    font-family: 'Proxima Nova', Arial, sans-serif !important;
  }
}

.gm-style .gm-style-iw-c {
  padding: 0 !important;
  max-height: unset !important;
}

.gm-style .gm-style-iw-d {
  max-height: unset !important;
  min-width: 290px !important;
}

.gm-style .gm-style-iw-tc {
  display: none !important;
}
